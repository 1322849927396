import React from "react";
import renderImage from "./renderImage";

function SliderBackArrow(props) {
  const { className, onClick } = props;
  return (
    <>
      <div>
        {" "}
        <img
          src={renderImage("backbtn")}
          className="footer-backbtn"
          onClick={onClick}
        />
      </div>
    </>
  );
}
export default SliderBackArrow;
