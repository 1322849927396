import beeper from "../Assets/Tronix_updated/beeper.png";
import capasitor100 from "../Assets/Tronix_updated/capacitor100.png";
import capasitor1000 from "../Assets/Tronix_updated/capacitor.png";
import diode from "../Assets/Tronix_updated/diode.png";
import dip from "../Assets/Tronix_updated/dip switch.png";
import junction from "../Assets/Tronix_updated/junction.png";
import ldr from "../Assets/Tronix_updated/ldr.png";
import led from "../Assets/Tronix_updated/led.png";
import pot from "../Assets/Tronix_updated/pot.png";
import power from "../Assets/Tronix_updated/power.png";
import res_100 from "../Assets/res_100_ohm.png";
import res_250 from "../Assets/Tronix_updated/resistor.png";
import tact from "../Assets/Tronix_updated/tact.png";
import timer from "../Assets/Tronix_updated/timer.png";
import transistor from "../Assets/Tronix_updated/transistor.png";
import two_way_switch from "../Assets/Tronix_updated/twowayswitch.png";
import smiple from "../Assets/bg/simple.png";
import seriesCircuit from "../Assets/bg/series.png";
import parallelCircuit from "../Assets/bg/prallel.png";
import resistorCircuit from "../Assets/bg/resistor.png";
import capacitorCircuit from "../Assets/bg/capacitor.png";
import voltageDividerCircuit from "../Assets/bg/voltage.png";
import semi_conductorCircuit from "../Assets/bg/semi_conductor.png";
import transistorCircuit from "../Assets/bg/transistor.png";
import freedomCircuit from "../Assets/bg/freedom.png";
import MaskGroup from "../Assets/bg/MaskGroup.png";
import MaskGroup3 from "../Assets/bg/Mask Group 3@2x.png";
import backbtn from "../Assets/bg/backbtn.png";
import nextbtn from "../Assets/bg/nextbtn.png";
import headerbtn from "../Assets/bg/headerbtn.png";
import erasebtn from "../Assets/bg/erasebtn.png";
import helpbtn from "../Assets/bg/helpbtn.png";
import project from "../Assets/bg/project.png";
import playTronic from "../Assets/bg/playTronic.png";
import navbarImg from "../Assets/bg/navbarImg.png";
import min from "../Assets/bg/min.png";
import max from "../Assets/bg/max.png";
import PlayTronixH from "../Assets/helpScreen/PlayTronixH.png";
import PlayTronixH1 from "../Assets/helpScreen/PlayTronixH1.png";
import rightArrow from "../Assets/helpScreen/right-arrow.png";
import leftArrow from "../Assets/helpScreen/left-arrow.png";
function renderImage(imageName) {
  switch (imageName) {
    case "playTronic":
      return playTronic;
    case "project":
      return project;
    case "navbarImg":
      return navbarImg;
    case "nextbtn":
      return nextbtn;
    case "headerbtn":
      return headerbtn;
    case "erasebtn":
      return erasebtn;
    case "helpbtn":
      return helpbtn;
    case "backbtn":
      return backbtn;
    case "MaskGroup3":
      return MaskGroup3;
    case "MaskGroup":
      return MaskGroup;
    case "freedomCircuit":
      return freedomCircuit;
    case "parallelCircuit":
      return parallelCircuit;
    case "resistorCircuit":
      return resistorCircuit;
    case "capacitorCircuit":
      return capacitorCircuit;
    case "voltageDividerCircuit":
      return voltageDividerCircuit;
    case "semi_conductorCircuit":
      return semi_conductorCircuit;
    case "transistorCircuit":
      return transistorCircuit;
    case "seriesCircuit":
      return seriesCircuit;
    case "simpleCircuit":
      return smiple;
    case "beeper":
      return beeper;
    case "capasitor100":
      return capasitor100;
    case "capasitor1000":
      return capasitor1000;
    case "diode":
      return diode;
    case "dip":
      return dip;
    case "junction":
      return junction;
    case "ldr":
      return ldr;
    case "pot":
      return pot;
    case "power":
      return power;
    case "res_100":
      return res_100;
    case "res_250":
      return res_250;
    case "tact":
      return tact;
    case "timer":
      return timer;
    case "transistor":
      return transistor;
    case "two_way_switch":
      return two_way_switch;
    case "led":
      return led;
    case "max":
      return max;
    case "min":
      return min;
    case "rightArrow":
      return rightArrow;
    case "leftArrow":
      return leftArrow;
    case "PlayTronixH":
      return PlayTronixH;
    case "PlayTronixH1":
      return PlayTronixH1;
  }
}
export default renderImage;
