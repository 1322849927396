import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import navbarImg from "../Assets/bg/MaskGroup.png";
import renderImage from "./renderImage";
import closeBtn from "../Assets/helpScreen/close.png";

const userAgent = window.navigator.userAgent;
const HeaderNavBar = (props) => {
  const [handle, setHandle] = useState(1);
  const [ishelpButton, sethelpButton] = useState(false);
  const handleClickhelpbtn = () => {
    sethelpButton(true);
  };

  const closeModal = () => {
    sethelpButton(false);
  };
  return (
    <>
      {/* navbar image */}
      <div id="navbar" style={{ backgroundImage: `url("${navbarImg}")` }}>
        <img src={renderImage("playTronic")} className="navbar-Text" />
      </div>
      {/* backbtn sidebtn */}
      <div className="body-backbtn">
        <Link to="/">
          <img
            src={renderImage("backbtn")}
            onClick={() => {
              sessionStorage.setItem("freedomCircuit", null);
            }}
            style={{ width: "10vh" }}
          />
        </Link>
      </div>
      <div className="body-nextbtn">
        <img
          src={renderImage("helpbtn")}
          style={{ width: "7vh", border: "2px solid blue" }}
          onClick={handleClickhelpbtn}
        />
      </div>
      <div className="erasebtn">
        <img
          src={renderImage("erasebtn")}
          onClick={() => {
            window.location.reload();
          }}
          style={{ width: "9vh" }}
        />
      </div>

      {ishelpButton && (
        <div className="help_MainContainers">
          <div className="help_Container">
            <img className="help_Image" src={renderImage("PlayTronixH1")} />
            <div className="help_text1">
              <p className="help_textP">
                Erase button: to erase the circuit that you created for a fresh
                screen
              </p>
            </div>
            <div className="help_text2">
              <p className="help_textP">
                Back button: to back to the list of lessons
              </p>
            </div>
            <div className="help_text3">
              <p className="help_textP">
                Components selection panel: All the components for the opened
                lesson is available below. to use the component, you have to
                drag -n- drop the component to the workspace & connect it as per
                the correct circuit.
              </p>
            </div>
            <div className="help_text4">
              <p className="help_textP">
                Next / Previous button: Click this button to scroll & see the
                list of all the PlayTronix blocks for the respective lesson
              </p>
            </div>
          </div>

          <div
            onClick={closeModal}
            style={{
              position: "absolute",
              top: "12.5vh",
              right: "15%",
              zIndex: "100000",
              transform: "translate(-50%,-50%)",
            }}
          >
            <img src={closeBtn} style={{ width: "7vh" }} />
          </div>
        </div>
      )}

      {/Mobile/.test(userAgent) ? (
        <div className="fullscreen">
          {handle ? (
            <img
              src={renderImage("max")}
              onClick={() => {
                document.documentElement.requestFullscreen();
                setHandle(0);
              }}
              style={{ width: "9vh" }}
            />
          ) : (
            <img
              src={renderImage("min")}
              onClick={() => {
                document.exitFullscreen();
                setHandle(1);
              }}
              style={{ width: "9vh" }}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default HeaderNavBar;
