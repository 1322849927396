import React, { Component } from "react";
import { Outlet, Link } from "react-router-dom";
import Main from "../../componets/Main";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import renderImage from "../renderImage";
import closeBtn from "../../Assets/helpScreen/close.png";

class DaySelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "day0",
      ishelpButton: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.handleClickhelpbtn = this.handleClickhelpbtn.bind(this);
  }
  closeModal() {
    this.setState({ ishelpButton: false });
  }

  handleClickhelpbtn() {
    this.setState({ ishelpButton: true });
  }
  render() {
    sessionStorage.clear();

    const onClick = (e, val) => {
      console.log(val);
      this.setState({ value: val });
    };
    const mainRender = (e) => {
      if (e === "day0") return <></>;
      else if (e === "simpleCircuit")
        return <Main beeper={true} led={true} tact={true} type={e} />;
      else if (e === "seriesCircuit")
        return <Main led={true} tact={true} type={e} beeper={true} />;
      else if (e === "parallelCircuit")
        return (
          <Main junction={true} led={true} tact={true} beeper={true} type={e} />
        );
      else if (e === "resistorCircuit")
        return (
          <Main
            led={true}
            junction={true}
            res_100={true}
            res_250={true}
            tact={true}
            type={e}
          />
        );
      else if (e === "capacitorCircuit")
        return (
          <Main
            beeper={true}
            capacitor100={true}
            capacitor1000={true}
            tact={true}
            junction={true}
            type={e}
          />
        );
      else if (e === "voltageDividerCircuit")
        return (
          <Main led={true} pot={true} tact={true} junction={true} type={e} />
        );
      else if (e === "semi-conductorDiodeCircuit")
        return <Main led={true} diode={true} tact={true} type={e} />;
      else if (e === "transistorCircuit")
        return (
          <Main
            led={true}
            transistor={true}
            tact={true}
            ldr={true}
            res_100={true}
            junction={true}
            type={e}
          />
        );
      else if (e === "freedomCircuit") console.log("freedom");
      return (
        <Main
          beeper={true}
          capacitor100={true}
          capacitor1000={true}
          diode={true}
          dip={true}
          junction={true}
          ldr={true}
          led={true}
          pot={true}
          res_100={true}
          res_250={true}
          tact={true}
          timer_ic={true}
          transistor={true}
          two_way_switch={true}
          power={true}
          type={e}
        />
      );
    };

    return (
      <>
        {this.state.ishelpButton ? (
          <>
            <div className="help_MainContainer">
              <div className="help_Container">
                <img className="help_Image" src={renderImage("PlayTronixH")} />
                <div className="help_text">
                  <p
                    style={{
                      backgroundColor: "white",
                      padding: "4px",
                      fontSize: "1.8vh",
                      textAlign: "left",
                      color: "#707070",
                      fontFamily: "Halcyon_Regular",
                      borderRadius: "12px",
                      marginBottom: "0px",
                    }}
                  >
                    Lessons: These are pro-programmed fixed lessons list. you
                    can open any of the lessons to create a circuit & simulate
                    the same.
                  </p>
                </div>
              </div>
              {/* <div>This is the modal content!</div> */}

              <div
                onClick={this.closeModal}
                style={{
                  position: "absolute",
                  top: "12.5vh",
                  right: "15%",
                  zIndex: "100000",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <img src={closeBtn} style={{ width: "7vh" }} />
              </div>
              {/* <button onClick={this.closeModal}>Close Modal</button> */}
            </div>
          </>
        ) : (
          <div className="Parent-Container" style={{ height: "100%" }}>
            <div>
              <nav id="nav">
                <img
                  src={renderImage("navbarImg")}
                  style={{ width: "100%", height: "100%" }}
                />
                <img
                  src={renderImage("playTronic")}
                  style={{
                    width: "17%",
                    position: "absolute",
                    top: "50%",
                    left: "13%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
              </nav>

              <div className="container">
                <div id="main">
                  <img
                    src={renderImage("helpbtn")}
                    onClick={this.handleClickhelpbtn}
                  />
                </div>

                <div id="content1">
                  <div style={{ position: "relative" }}>
                    <Link to="/simpleCircuit">
                      <img
                        src={renderImage("project")}
                        style={{
                          width: "100%",
                        }}
                      />
                      <p className="Text">SimpleCircuit</p>
                    </Link>
                  </div>
                  <div style={{ position: "relative" }}>
                    <Link to="seriesCircuit">
                      {" "}
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text">SeriesCircuit</p>
                    </Link>{" "}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Link to="parallelCircuit">
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text">ParallelCircuit</p>
                    </Link>{" "}
                  </div>
                </div>

                <div id="content2">
                  {" "}
                  <div style={{ position: "relative" }}>
                    <Link to="resistorCircuit">
                      {" "}
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text">ResistorCircuit</p>
                    </Link>{" "}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Link to="capacitorCircuit">
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text">CapacitorCircuit</p>
                    </Link>{" "}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Link to="voltageDividerCircuit">
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text">VoltageDividerCircuit</p>
                    </Link>{" "}
                  </div>
                </div>

                <div id="content3">
                  {" "}
                  <div style={{ position: "relative" }}>
                    <Link to="semi-conductorDiodeCircuit">
                      {" "}
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text1">Semi-ConductorDiodeCircuit</p>
                    </Link>{" "}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Link to="/transistorCircuit">
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text">TransistorCircuit</p>
                    </Link>{" "}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Link to="/freedomCircuit">
                      {" "}
                      <img
                        src={renderImage("project")}
                        style={{ width: "100%" }}
                      />
                      <p className="Text">FreedomCircuit</p>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div id="turn"> PLease rotate your device!</div>
      </>
    );
  }
}
export default DaySelect;
