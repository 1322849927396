import React from "react";
import renderImage from "./renderImage";

function SliderNextArrow(props) {
  const { className, onClick } = props;

  return (
    <>
      <div>
        <img
          src={renderImage("nextbtn")}
          className="footer-nextbtn"
          onClick={onClick}
        />
      </div>
    </>
  );
}
export default SliderNextArrow;
